.place
  height: 100%
  background-color: #fff
  cursor: pointer
  display: block
  color: #444444
  position: relative
  border: 1px solid #e0e2e2
  border-radius: 4px
  -webkit-tap-highlight-color: transparent
  border-radius: 16px
  overflow: hidden
  display: grid
  grid-auto-rows: max-content
  grid-template-rows: auto
  text-decoration: none
  &:hover
    filter: drop-shadow(rgba(0, 0, 0, 25%) 0 4px 4px)
    .as-link
      color: #D15E26

  &__label
    font-family: Poppins500, sans-serif
    position: absolute
    top: 8px
    left: 8px
    height: 24px
    display: flex
    align-items: center
    justify-content: center
    padding: 0 16px
    color: #FFF
    font-size: 14px
    line-height: 16px
    border-radius: 16px
    background: #501A6A
    z-index: 1

  &__img-wrapper, &__img-placeholder, &__img-placeholder-cover
    margin-bottom: 16px

  &__img, &__img-placeholder, &__img-placeholder-cover, &__img-wrapper
    height: 100%
    width: 100%
    object-fit: cover
    aspect-ratio: 4/3
    //margin-bottom: 16px
    height: 236px
    object-position: center
    @media (min-width: 768px)
      height: 272px

  &__img-placeholder-cover
    position: absolute
    top: 0
    left: 0
    width: 100%
    opacity: 0.7
    background: black

  &__img-placeholder-label
    font-family: Poppins600, sans-serif
    color: #FFF
    font-size: 30px
    position: absolute
    top: 98px
    margin: 0 auto
    display: flex
    align-items: center
    justify-content: center
    width: 100%
    line-height: 40px
    @media (min-width: 768px)
      top: 116px

  &__title
    font-family: Poppins600, sans-serif
    overflow: hidden
    display: -webkit-box
    -webkit-line-clamp: 2
    line-clamp: 2
    -webkit-box-orient: vertical
    color: #000
    font-size: 18px
    line-height: 24px
    letter-spacing: 0.16px
    margin-bottom: 8px
    padding: 0 16px
    height: 48px
    margin-top: 0

  &__category
    font-family: Poppins200, sans-serif
    color: #000
    font-size: 12px
    font-style: normal
    font-weight: 400
    line-height: 16px
    letter-spacing: 0.16px
    text-transform: uppercase
    padding: 0 16px

  &__guests-wrapper
    font-family: Poppins500, sans-serif
    padding: 16px
    border-bottom: solid 1px #F4F1E9
    font-size: 16px
    line-height: 20px
    color: #444444
    display: flex

  &__guests-block
    display: flex
    align-items: center
    align-self: center
    margin: 0 auto
    svg
      fill: #C0B199
      margin-right: 8px

  &__description
    font-family: Poppins200, sans-serif
    overflow: hidden
    display: -webkit-box
    -webkit-line-clamp: 5
    line-clamp: 2
    -webkit-box-orient: vertical
    padding: 0px 16px
    margin: 16px 0
    color: black
    font-size: 14px
    line-height: 20px
    letter-spacing: 0.08px
    *
      margin: 0
      padding: 0
      display: inline
    br
      display: none
    h4, h3, h2
      font-family: Poppins500, sans-serif
      font-size: 14px
    ul
      padding-left: 10px

  &__price-block
    padding: 16px
    display: flex
    align-items: center
    justify-content: space-between
    height: 47px
    &.border
      border-top: solid 1px #F4F1E9

  &__price-label
    font-family: Poppins300, sans-serif
    color: #000
    font-size: 16px
    line-height: 20px
    letter-spacing: 0.08px
    &.as-link
      text-decoration: underline
      color: #E8753D

  &__price-value
    font-family: Poppins600, sans-serif
    color: #FE4C00
    font-size: 16px
    font-weight: 900
    line-height: 20px
    letter-spacing: 0.08px
    span
      font-family: Poppins300, sans-serif
      color: #000
      font-weight: 600

  &__suitable-for-list
    margin: 16px
    //min-height: 92px

  &__suitable-for-list-item
    font-family: Poppins400, sans-serif
    color: #000
    font-size: 14px
    line-height: 20px
    margin-bottom: 4px
    display: flex
    align-items: flex-start
    letter-spacing: 0.08px
    &:last-child
      margin-bottom: 0
    svg
      margin-right: 4px
      fill: #C0B199
      top: 2px
      position: relative

  &.premium
    border: 1px solid #E8753D
    background: #FFFCF3

    .place__guests-wrapper
      border-bottom-color: #E8753D

    .place__price-block.border
      border-top-color: #E8753D

    .place__suitable-for-list-item,
    .place__guests-block
      svg
        fill: #E8753D
